<template>
  <div
    id="nav"
    :class="{'pricePage':this.$route.name === 'price'}"
  >
    <div
      class="nav"
      :class="{'notTop' : windowTop}"
    >
      <div
        class="navWrap"
        ref="navbarH"
      >
        <h1>永恆國際</h1>
        <img
          class="logo"
          @click="routerChange('/')"
          src="../assets/image/logo.png"
        >
        <span class="burgerWrap">
          <div
            @click="toggleBar"
            id="nav-icon1"
            :class="{'open' : burger}"
            class="navClose"
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </span>
        <ul
          class="navBar"
          ref="navbar"
        >
          <li @click="navHerf('nav')">回到首頁</li>
          <li @click="navHerf('service')">服務介紹</li>
          <li @click="navHerf('seo')">SEO服務</li>
          <li @click="navHerf('template')">樣板介紹</li>
          <li @click="routerChange('price')">價格說明</li>
          <li @click="navHerf('connection')">聯絡我們</li>
        </ul>
      </div>
    </div>
    <div class="beginning1">
      <VueSlickCarousel v-bind="setting">
        <div class="bannerP2">
          <div class="bannerP2Contnet"  v-if="this.$route.name === 'home'">
            <p class="title-one">BRING YOU A GOOD DESING <span v-if="screenWidth >=500">------</span></p>
            <p class="title-one title-fives">不僅是視覺美觀</p>
            <p class="title-one title-fives">更要優良使用性</p>
            <p class="title-tree">為客戶帶來全方面優良體驗</p>
          </div>
        </div>
        <div class="bannerP2">
          <div class="bannerP2Contnet"  v-if="this.$route.name === 'home'">
            <p class="title-one">BRING YOU A GOOD DESIGN <span v-if="screenWidth >=500">------</span></p>
            <p class="title-one title-fives">唯有專注，才能給您最好</p>
            <p class="title-one title-fives">提供網站設計最佳服務品質</p>
            <p class="title-tree">為客戶帶來全方面優良體驗</p>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {

  components: { VueSlickCarousel },
  data () {
    return {
      burger: true,
      windowTop: 0,
      someLocalProperty: 0,
      screenWidth: '',
      setting: {
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 5000
      }
    }
  },
  watch: {
    '$route' (to, from) {
      if (document.body.clientWidth <= 768) {
        this.burger = false
      }
      if (this.$route.name !== 'home') {
        this.setting.autoplay = false
      } else {
        this.setting.autoplay = true
      }
    },
    burger () {
      if (document.body.clientWidth <= 768) {
        if (this.burger) {
          this.$refs.navbar.style.height = '100%'
        } else {
          this.$refs.navbar.style.height = 0
        }
      } else {
        if (this.burger) {
          this.$refs.navbar.style['margin-right'] = 0
        } else {
          this.$refs.navbar.style['margin-right'] = -(this.$refs.navbar.getBoundingClientRect().width) - 20 + 'px'
        }
      }
    }
  },
  methods: {
    routerChange (name) {
      if (this.$route.name === name) {
        window.scrollTo(0, 0)
        return
      }
      window.scrollTo(0, 0)
      this.$router.push(name)
    },
    navHerf (name) {
      const ths = this
      let interval
      if (interval) {
        clearTimeout(interval)
      }
      function scroll () {
        let scrollNum = document.querySelector(`#${name}`).offsetTop
        if (name !== 'nav') {
          scrollNum = scrollNum - ths.$refs.navbarH.offsetHeight
        }
        if (document.body.clientWidth <= 768) {
          ths.burger = false
        }
        window.scrollTo(0, scrollNum)
      }
      if (ths.$route.name !== 'home' && name !== 'connection') {
        ths.$router.push('/')
        interval = setTimeout(function () {
          scroll()
        }, 500)
        return
      }
      if (!document.querySelector(`#${name}`)) {
        return
      }
      scroll()
    },
    handleScroll (e) {
      const windowTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      this.windowTop = windowTop
    },
    toggleBar () {
      this.burger = !this.burger
    },
    handleresize (e) {
      const screenWidth = document.body.clientWidth
      this.screenWidth = screenWidth
      if (screenWidth <= 768) {
        if (this.burger) {
          this.$refs.navbar.style.height = '100%'
        } else {
          this.$refs.navbar.style.height = '0px'
        }
      } else {
        if (this.burger) {
          this.$refs.navbar.style.height = 'auto'
          this.$refs.navbar.style['margin-right'] = 0
        } else {
          this.$refs.navbar.style.height = 'auto'
          this.$refs.navbar.style['margin-right'] = -(this.$refs.navbar.getBoundingClientRect().width) - 20 + 'px'
        }
      }
    }
  },
  created () {
    this.$nextTick(() => {
      this.toggleBar()
      this.screenWidth = document.body.clientWidth
    })
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleresize)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleresize)
  }
}
</script>
<style lang="scss">
.navWrap {
  max-width: 1280px;
  padding: 0 20px;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden;
  flex-wrap: wrap;
}
.burgerWrap {
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.navBar {
  display: flex;
  align-items: center;
  transition: 0.5s ease-in-out;
  overflow: hidden;
  background-color: transparent;
  li {
    color: white;
    font-size: 18px;
    margin-right: 10px;
    cursor: pointer;
    &:hover {
      color: #ff8b46;
    }
  }
}
@keyframes navbar {
  from {
    top: -140px;
  }
  to {
    top: 0px;
  }
}
.notTop {
  animation: navbar 1s;
  transition: 1s ease-in-out;
  background-color: rgba(black, 0.6);
}
@media (max-width: 768px) {
  .notTop {
    animation: none;
  }
}
.logo {
  margin-right: auto;
  cursor: pointer;
}
@media (max-width: 850px) {
  .navBar {
    li {
      font-size: 16px !important;
    }
  }
}
@media (max-width: 768px) {
  .navWrap {
    img {
      height: 100px;
    }
  }
  .navBar {
    width: 100vw;
    position: fixed;
    top: 100px;
    left: 0;
    display: flex;
    flex-direction: column;
    li {
      padding: 20px 0;
      background-color: white;
      color: black;
      width: 100%;
      margin-right: 0;
      text-align: center;
      &:hover {
        background-color: rgb(245, 245, 245);
      }
    }
  }
}
.pricePage {
  height: 131px;
  overflow: hidden;
}
.bannerP1 {
  background-image: url("../assets/image/bannerwebsite.png");
  background-position: center;
  width: 100%;
  height: 100vh;
  position: relative;
  .bannerP1Contnet {
    position: absolute;
    right: 10%;
    top: 25%;
  }
}
.bannerP2 {
  background-image: url("../assets/image/BANNER-02.jpg");
  background-position: center;
  width: 100%;
  height: 100vh;
  position: relative;
  .bannerP2Contnet {
    position: absolute;
    left: 15%;
    top: 25%;
    .title-tree {
      padding: 0;
    }
  }
}
h1 {
  text-indent: 5000%;
  width: 0;
  height: 0;
  white-space: nowrap;
  color: transparent;
}
@media (max-width: 768px) {
  .title-fives {
    font-size: 45px !important;
  }
  .title-two {
    padding-left: 100px !important;
  }
  .bannerP1Contnet {
  }
}
@media (max-width: 768px) {
  .title-fives {
    font-size: 40px !important;
  }
  .title-two {
    padding-left: 100px !important;
  }
  .bannerP1Contnet {
  }
}
@media (max-width: 500px) {
  .title-two {
    padding-left: 80px !important;
    padding-top: 0 !important;
  }
  .bannerP1Contnet {
    right: 50% !important;
    width: 90%;
    transform: translate(50%, 0%);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .bannerP2Contnet {
    left: 50% !important;
    width: 90%;
    transform: translate(-50%, 0%);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .title-one {
    font-size: 20px !important;
  }
  .title-fives {
    font-size: 25px !important;
  }
  .title-tree {
    font-size: 18px !important;
  }
}
</style>
