import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueMeta from 'vue-meta'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSweetalert2 from 'vue-sweetalert2'

import 'sweetalert2/dist/sweetalert2.min.css'

Vue.use(VueSweetalert2)
Vue.use(VueAxios, axios)
Vue.config.productionTip = false
Vue.use(VueMeta)
axios.defaults.headers['Content-Type'] = 'application/json;Accept;application/json'
axios.defaults.headers.Accept = 'application/json'

new Vue({
  router,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
