<template>
  <div id="connection">
    <div class="footer">
      <div class="left">
        <p class="enTitle">DEMAND</p>
        <p class="a1-1 a1 chTitle">聯絡我們</p>
        <!-- <div class="contentWrap">
          <p class="lineId">LINE:@774nizkd</p>
          <img
            src="../assets/image/qrcord.png"
            alt=""
          >
        </div> -->
      </div>
      <div class="right">
        <div class="mail">
          <img
            src="../assets/image/mail.jpg"
            alt=""
          >
          eternal@eternal-net.com
        </div>
        <div class="form">
          <div class="inputGroup">
            <label>聯絡人*</label>
            <input v-model="formData.name" type="text">
          </div>
          <div class="inputGroup">
            <label>連絡電話</label>
            <input v-model="formData.phone" type="text">
          </div>
          <div class="inputGroup">
            <label>Email</label>
            <input  v-model="formData.email" type="text">
          </div>
          <div class="inputGroup">
            <label>所在地</label>
            <input v-model="formData.location" type="text">
          </div>
          <div class="areaGroup">
            <label>問題及需求*</label>
            <textarea
             v-model="formData.note"
              name=""
              id=""
              cols="30"
              rows="10"
            ></textarea>
          </div>
          <div class="sendBtn">
            <button @click="sendMessage">SEND</button>
          </div>
        </div>
      </div>
    </div>
    <div class="footerWrap">
      <div class="footerInfo">
        <img
          src="../assets/image/logo.png"
          alt=""
        >
        <div class="info">
          <p>服務時間:週一 ~ 週五 09:00 ~ 18:00</p>
          <p>© 2020 永恆國際網路有限公司 版權所有</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  data () {
    return {
      formData: {
        note: '',
        location: '',
        email: '',
        phone: '',
        name: ''
      }
    }
  },
  methods: {
    sendMessage () {
      let check = true
      const data = this.formData
      const message = {
        name: '聯絡人',
        note: '問題及需求'
      }
      Object.keys(data).forEach(e => {
        if (data[e] === '' && message[e]) {
          this.$swal(`請輸入${message[e]}`)
          check = false
        }
      })
      if (check) {
        axios.post(`${process.env.VUE_APP_DEFAULT_SRC}/api/contact-us`, data).then(res => {
          if (res.data) {
            this.$swal('送出成功')
            this.formData = {
              note: '',
              location: '',
              email: '',
              phone: '',
              name: ''
            }
          }
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.footer {
  display: flex;
  background-color: #f5f5f5;
  justify-content: center;
  height: auto;
  padding: 50px 0;
  flex-direction: column;
  align-items: center;
}

.contentWrap {
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 250px;
  }
}
.enTitle {
  color: #ff8b46;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 5px;
}
.left {
  // margin-right: 10px;
  width: 100%;
}
.right {
  // margin-left: 10px;
  // width: 100%;
}
@media (max-width: 768px) {
  .footer {
    flex-wrap: wrap;
  }
  .right,
  .left {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.right {
  margin-top: 30px;
}
.mail {
  margin-bottom: 20px;
}
.form {
  display: flex;
  flex-wrap: wrap;
  max-width: 600px;
  .inputGroup {
    width: calc(50% - 20px);
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 20px;
  }
  .areaGroup {
    width: calc(1000% - 20px);
    margin-right: 10px;
    margin-left: 10px;
  }
}
.sendBtn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  margin-top: 10px;
  button {
    background-color: #ff8b46;
    border: none;
    border-radius: 2px;
    outline: none;
    color: white;
    padding: 5px 12px;
  }
}
.footerWrap {
  background-color: #3a3a3a;
  display: flex;
  justify-content: center;
  .footerInfo {
    display: flex;
    max-width: 1280px;
    padding: 0 20px 20px 20px;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    .info {
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      p {
        margin-top: 5px;
      }
    }
  }
}
@media (max-width: 620px) {
  .footerInfo {
    flex-direction: column;
    align-items: center;
  }
}
.lineId{
  margin-bottom: 10px;
}
</style>
