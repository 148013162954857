<template>
  <div id="app">
    <banner />
    <router-view />
    <footers></footers>
  </div>
</template>

<script>
import banner from './layout/banner.vue'
import footers from './layout/footer.vue'
export default {
  components: {
    banner,
    footers
  },
  metaInfo: {
    meta: [
      { name: 'description', content: '最專業全方位網站系統客製化服務、SEO搜尋引擎最佳化。您缺少一個合適的資訊系統網站或最佳的SEO為您帶來效益嗎？找永恆國際就對了！' },
      { name: 'author', content: '永恆國際(Eternal Design)' }
    ]
  },
  data () {
    return {
    }
  }
}
</script>
<style lang="scss">
@import './assets/scss/main.scss';
#app{
  overflow: hidden;
}
</style>
